@font-face {
    font-family: 'Gilroy-Light';
    font-style: normal;
    font-display: swap;
    src: url('../styles/fonts/Gilroy-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-display: swap;
    src: url('../styles/fonts/Gilroy-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-display: swap;
    src: url('../styles/fonts/Gilroy-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-display: swap;
    src: url('../styles/fonts/Gilroy-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Gilroy-ExtraBold';
    font-style: normal;
    font-display: swap;
    src: url('../styles/fonts/Gilroy-ExtraBold.otf');
}
@font-face {
    font-family: 'Gilroy-Heavy';
    font-style: normal;
    font-display: swap;
    src: url('../styles/fonts/Gilroy-Heavy.ttf') format('truetype');
}
