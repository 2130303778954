.darkMode {
    background-color: $dark-full_White;

    header {
        background-color: $dark-full_White;

        .top_controls_wrapper {
            background: rgba(251, 212, 212, 0.29);

            .mode_switcher {
                .language_wrap {
                    button {
                        color: $dark-full_Black;

                        &.active {
                            background-color: $dark-chilly_red;
                            color: $dark-amber_card_background;
                        }
                    }
                }

                .colormode_wrap {
                    .colorMode_icon {
                        color: $dark-amber_card_background;
                    }
                }
            }

            .location_update {
                span {
                    color: $dark-full_Black;

                    a {
                        color: $dark-amber_card_background;
                    }
                }

                .change-btn {
                    background: $dark-chilly_red;
                }
            }
        }

        .header_wrapper {
            .container {
                .authControls {
                    .cartIconwrap {
                        .cartIcon {
                            background: $dark-light_grey;

                            svg {
                                g {
                                    fill: $dark-full_Black;
                                }
                            }
                        }

                        h4 {
                            color: $dark-chilly_red;

                            small {
                                color: $dark-full_Black;
                            }
                        }
                    }

                    .userDatawrap {
                        h4 {
                            color: $dark-full_Black;

                            small {
                                color: $dark-dark_grey;
                            }
                        }
                    }
                }
            }
        }
    }

    .carousel_wrapper {
        .container {
            .hero_title {
                button {
                    background: $dark-chilly_red;
                    color: $dark-full_White;
                }

                h1 {
                    color: $dark-full_Black;

                    small {
                        color: $dark-chilly_red;
                    }
                }

                span {
                    color: $dark-dark_grey;
                }
            }
        }
    }

    .searchBar_container {
        .container {
            display: flex;

            .searchBar_wrapper {
                border: 1px solid $light-light_grey;
                background: #3030309c;

                .searchBar {
                    background: $dark-full_White;
                    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);

                    .form-control {
                        color: $dark-full_Black;
                        background: $dark-negative;
                    }
                }
            }

            button {
                svg {
                    stroke: $dark-full_Black;
                }
            }
        }
    }

    .featuresSeaction {
        .container {
            .featuresWrap {
                p {
                    color: $dark-dark_grey;
                }

                h3 {
                    color: $dark-full_Black;

                    small {
                        color: $dark-chilly_red;
                    }
                }
            }
        }
    }

    .offersContainer {
        &::after {
            background-color: $dark-chilly_red;
        }
    }

    .storeSection {
        .storeWrapper {
            box-shadow: 0px 15px 68px 0px rgba(0, 0, 0, 0.25);

            .textBlock {
                h3 {
                    color: $dark-full_Black;
                }

                p {
                    color: $dark-full_Black;
                }
            }
        }
    }

    .otpInput {
        input {
            border: 1px solid $dark-full_Black !important;
            background: $dark-light_grey !important;
        }

        a {
            color: $dark-full_Black;
        }
    }

    .populardish {
        border-bottom: 1px solid #d8d8d8;

        .populardish-txt {
            h2 {
                color: $dark-full_Black;
            }

            p {
                color: $dark-dark_grey;
            }
        }

        .PopulardishSlider-container {
            width: 100%;

            .slick-arrow {
                border: 1px solid $dark-light_grey;

                &.isActive {
                    background-color: $dark-chilly_red !important;
                }
            }
        }
    }

    .client-says {
        .slick-dots {
            li {
                button:before {
                    background-color: rgba(255, 255, 255, 0.13);
                }

                &.slick-active {
                    button:before {
                        background-color: $dark-amber;
                    }
                }
            }
        }

        .container {
            .client-saysWrapper {
                box-shadow: 0px 50px 73px 0px rgba(0, 0, 0, 0.25);

                .client-says-wrap {
                    background: $dark-chilly_red;

                    .client-says-txt {
                        .client-says-header {
                            h2 {
                                color: $dark-negative;
                            }

                            span {
                                color: $dark-negative;
                            }
                        }

                        .client-says-body {
                            p {
                                color: $dark-negative;
                            }
                        }

                        .client-says-footer {
                            .userDetails {
                                h4 {
                                    color: $dark-negative;
                                }

                                .ratting-wrap {
                                    p {
                                        color: $dark-negative;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.drawer {
        background: $dark-light_grey;

        .profileDetails-wrap {
            background: linear-gradient(180deg, #202021 0%, #333 100%);
            border: 1px solid $dark-full_White;

            h4 {
                color: $dark-full_Black !important;
            }
        }

        .sheetHeader {
            h4 {
                color: $dark-full_Black;

                small {
                    color: $dark-dark_grey;
                }
            }
        }

        .profileDetails {
            h4 {
                color: $dark-full_Black;

                small {
                    color: $dark-dark_grey;
                }
            }
        }

        .formControl {
            color: $dark-dark_grey !important;
        }

        .edit_Header {
            .editBtn {
                color: $dark-chilly_red;
            }

            h4 {
                color: $dark-full_Black;
            }
        }

        .settingsSection {
            ul {
                li {
                    a {
                        div {
                            .menuText {
                                color: $dark-full_Black;

                                &.deleteAccount {
                                    color: $dark-chilly_red;
                                }
                            }
                        }
                    }
                }
            }
        }

        .logoutProfile {
            border-radius: $border-radius-small;
            background: $dark-chilly_red_dark;
            color: $dark-full_Black;
        }
    }

    .orderCardList {
        li.list {
            border-bottom: solid 1px #d7d7d7;
        }
    }

    .outlets {
        h4 {
            color: $dark-full_Black;

            small {
                color: $dark-chilly_red;
            }
        }

        p {
            color: $dark-dark_grey;
        }
    }

    footer {
        .container {
            &:nth-child(2) {
                border-top: 0.5px solid #d4cc27;
            }

            p {
                color: #909090;
            }

            .width66 {
                ul {
                    li {
                        h4 {
                            color: $dark-full_Black;
                        }

                        a {
                            color: #7c7c7c;
                        }
                    }
                }
            }
        }
    }

    // Auth Styles

    .signinContainer {
        .imageContainer {
            background-color: $dark-chilly_red;
        }

        .fieldContainer {
            .backButton {
                border-radius: $border-radius-small;
                background-color: $dark-full_Black;
                color: $dark-full_White;
            }

            .formField {
                h3 {
                    color: $dark-full_Black;

                    small {
                        color: $dark-dark_grey;
                    }
                }

                button {
                    border-radius: $border-radius-small;
                    background: $dark-chilly_red;
                    color: $dark-full_Black;
                }
            }
        }
    }

    // Common Styles

    .avatar {
        border: 2px solid $dark-amber;

        .avatar_icon {
            background: #3b333c;
        }
    }

    .toggle-container {
        border: 1px solid var(--Grey, #e6e6e6);

        &.languageSelector {
            .disabled {
                background-color: #707070;
            }
        }
    }

    .dialog-button {
        color: $dark-full_Black;
        border: 1px solid var(--Shade-Prime, rgba(250, 248, 244, 0.07));
        background: var(--Chilly-Red, #be1622);
    }

    .disabled {
        background-color: #707070;
    }

    .overlay {
        background: rgba(11, 14, 23, 0.51);
    }

    .formGroup {
        label {
            color: $dark-full_Black !important;
        }

        .formControl {
            border: 1px solid $dark-full_Black;
            background: $dark-light_grey;
            color: $dark-dark_grey;


            &::placeholder {
                color: $dark-dark_grey;
                opacity: 1;
            }

            &::-ms-input-placeholder {
                color: $dark-dark_grey;
            }
        }
    }

    button {
        border-radius: $border-radius-small;

        &.primary_btn {
            background-color: $dark-chilly_red;
            color: $dark-full_Black;
        }

        &.default_btn {
            background-color: $dark-full_Black;
            color: $dark-full_White;
        }
    }

    .icon {
        svg {
            path {
                stroke: $dark-full_Black;
            }
        }
    }

    // Common Styles
    .PhoneInputInput {
        background: var(--Negative, #fff);
    }

    .PhoneInput {
        border: 1px solid var(--Grey, #e6e6e6);
        background: var(--Negative, #fff);
    }

    .chicking-modal {
        .chicking-modal-wraper {
            background: $dark-negative;

            .back-btn {
                transform: rotate(-0deg);
                transform-origin: center;
            }

            .chicking-modal-header {
                .chicking-modal-title {
                    h4 {
                        color: $dark-full_Black !important;
                    }
                }

                p {
                    color: $dark-dark_grey;
                }
            }

            .chicking-modal-body {
                .submint-btn {
                    background: $dark-light_grey;
                    border: 1px solid $dark-full_Black;
                    color: $dark-full_Black;

                    &:hover {
                        background: $dark-negative;
                    }
                }
            }

            .chicking-modal-footer {
                .btn-group {
                    button {
                        color: $dark-full_Black;
                        border: 1px solid $dark-light_grey;
                        background: $dark-light_grey;

                        &.close-btn {
                            border: 1px solid $dark-light_grey;
                        }

                        &.primary-btn {
                            background: $dark-chilly_red;
                            color: $dark-full_Black;
                        }
                    }
                }
            }
        }
    }

    .input-group {
        border: 1px solid $dark-full_Black;
        background: $dark-light_grey;
    }

    .items {
        .item-bg {
            background: #ffffff4b;

            figure {
                border: 6px solid rgba(255, 255, 255, 0.55);
                background: $light-negative;
            }
        }

        span {
            color: $dark-full_Black;
        }
    }

    .client-says-wrap {
        background: $light-chilly_red;

        .client-says-txt {
            .client-says-header {
                h2 {
                    color: $light-negative;
                }

                span {
                    color: $light-negative;
                }
            }

            .client-says-body {
                p {
                    color: $light-negative;
                }
            }

            .client-says-footer {
                .userDetails {
                    h4 {
                        color: $light-negative;
                    }

                    .ratting-wrap {
                        p {
                            color: $light-negative;
                        }
                    }
                }
            }
        }
    }

    .location-wrap {
        svg {
            path {
                stroke: $dark-full_Black;
            }
        }

        .location-block {
            .current-location-txt {
                color: $dark-amber;
            }

            .new-address-btn {
                border: 1px dashed $dark-dark_grey;
                color: $dark-full_Black;
            }
        }

        .saved-locations {
            h4 {
                color: $dark-full_Black;
            }

            .item-locations {
                border: 1px solid $dark-light_grey;
                background: $dark-light_grey;

                &.isActive,
                &:hover {
                    background: #292826;
                }

                .item-header {
                    h6 {
                        color: $dark-full_Black;
                    }

                    button {
                        background: $dark-amber;
                        color: $dark-full_Black;
                    }
                }

                p {
                    color: $dark-dark_grey;
                }
            }
        }

        .recent-search {
            h4 {
                color: $dark-full_Black;
            }

            .recent-search-list {
                span {
                    color: $light-dark_grey;
                }
            }
        }

        .location-map {
            .delivery-address {
                h6 {
                    color: $dark-full_Black;

                    span {
                        color: $dark-chilly_red_dark;
                    }
                }

                .btn-change {
                    border: 1px solid $light-light_grey;
                    background: $dark-light_grey;
                    color: $dark-full_Black;
                }
            }
        }

        .form-block {
            .save-list-wrap {
                h4 {
                    color: $dark-full_Black;
                }

                .save-list {
                    span {
                        color: $dark-full_Black;
                        background: $dark-light_grey;
                    }
                }
            }
        }

        .take-away-search {
            .item-locations {
                .left-block {
                    .txt-block {
                        h6 {
                            color: $dark-full_Black;
                        }

                        div {
                            span {
                                color: $dark-full_Black;
                            }
                        }
                    }
                }
            }
        }

        .schedule-time {
            h5 {
                color: $dark-full_Black;
            }

            .submint-btn {
                color: $light-full_White;
                background: $light-chilly_red;
            }

            .time-wrap {
                span {
                    color: $dark-full_Black;
                }

                .time-time {
                    select {
                        border: 1px solid $dark-light_grey;
                        background: $dark-negative;
                        padding: 11px 7px;
                    }

                    .btn-group {
                        border: 1px solid $light-light_grey;

                        .btn {
                            color: $dark-full_Black;

                            &.isAtive {
                                background: $light-chilly_red;
                            }
                        }
                    }
                }
            }
        }
    }

    .checkbox {
        span {
            &:before {
                border: 1px solid $dark-light_grey;
                background: $dark-negative;
            }
        }

        input:checked+span::before {
            background: $light-chilly_red;
        }

        input:checked+span:after {
            top: 9px;
            left: 7px;
            background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/TickIcon.svg) no-repeat;
        }
    }

    .schedule-time-wrap {
        .schedule-time {
            background: $dark-negative;
            border: 1px solid $dark-light_grey;
        }

        .opening-time {
            background: $dark-light_grey;
        }

        .time-picker__input {
            background: $dark-negative;
            color: $dark-full_Black;
        }

        .time-picker__button {
            color: $dark-full_Black;
        }

        .time-picker__button:hover:not(.time-picker__button--active) {
            background: $dark-light_grey;
        }
    }

    .category-detail {
        .category-detail-header {
            background: $dark-negative;

            .category-detail-carousel {
                .category-detail-list {
                    border: 1px solid none;
                    background: $light-shaded_white;

                    &.isActive {
                        background: $light-chilly_red;

                        h4,
                        li {
                            color: $light-negative !important;
                        }

                        .price-txt {
                            color: $light-amber !important;
                        }
                    }

                    .offer-block {
                        h6 {
                            color: $light-negative;
                            background: $light-amber;
                        }
                    }

                    .offer-block-txt {
                        h4 {
                            color: $dark-full_Black;
                        }

                        ul {
                            li {
                                color: $dark-full_Black;

                                &.price-txt {
                                    color: $dark-amber;
                                }
                            }
                        }
                    }
                }
            }
        }

        .category-detail-banner {
            &.category-detail-banner-bg {

                h4,
                p {
                    color: $dark-negative !important;
                }
            }

            .title-block {
                h4 {
                    color: $dark-full_Black !important;
                }

                p {
                    color: $dark-full_Black !important;
                }
            }

            .category-banner {
                &::after {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
                }
            }

            .txt-block {
                h3 {
                    color: $light-negative;

                    span {
                        color: $light-negative;
                    }
                }

                .btn-group {
                    .cart-btn {
                        background: $light-chilly_red;
                        color: var(--White, #fff);
                    }

                    .like-btn {
                        background: $light-shaded_white;
                    }
                }
            }
        }
    }

    .category-detail-card {
        .img-block {
            .like-btn-block {
                h6 {
                    color: $light-negative;
                    background: $light-amber;
                }

                .like-btn {
                    background: rgba(255, 255, 255, 0.3);
                }
            }

            .add-btn {
                color: $light-negative;
                background: $light-chilly_red;
            }
        }

        .txt-block {
            h4 {
                color: $dark-full_Black;
            }

            .veg-block {
                span {
                    color: $dark-dark_grey;
                }

                p {
                    color: $dark-full_Black;
                }
            }
        }
    }

    .order-type-modal {
        .chicking-modal-wraper {
            .chicking-modal-header {
                h4 {
                    color: $light-full_Black;
                }

                span {
                    color: $light-chilly_red;
                }
            }

            .chicking-modal-body {
                .order-type-list {
                    li {
                        border: 1px solid $dark-dark_grey;
                        background: $dark-negative;

                        &.isActive,
                        &:hover {
                            border: 1px solid #f9c9cc;

                            svg {
                                path {
                                    fill: $light-chilly_red;
                                }
                            }
                        }

                        figure {
                            figcaption {
                                color: $dark-full_Black;
                            }
                        }
                    }
                }

                .submint-btn {
                    background: $dark-chilly_red;
                    color: $dark-full_Black;
                    border: 1px solid $dark-chilly_red_dark;

                    &:hover {
                        background: $dark-chilly_red_dark;
                    }
                }
            }
        }
    }

    .quantitywrap {
        border: 1px solid $light-light_grey;

        svg {
            path {
                stroke: $dark-full_Black;
            }
        }
    }



    .cartitems-address-wrap .cartitems-item .list {
        p {
            color: $dark-full_Black !important;
        }

        svg {
            path {
                stroke: $dark-full_Black !important;
            }
        }
    }

    .bite-btn {
        background: $dark-negative;
        color: $dark-full_Black;
    }

    .graphic-block-wrap {
        .graphic-block {
            border: 2px solid $light-chilly_red;
            background: $light-negative;

            &::before {
                content: '';
                background: $light-chilly_red;
            }
        }

        &.veg {
            .graphic-block {
                border: 2px solid $light-green !important;
                background: $light-negative !important;

                &::before {
                    background: $light-green !important;
                }
            }
        }
    }

    .order-details-wrap {
        .order-list {
            h4 {
                color: $dark-full_Black;
            }

            .quantity-wrap {
                h5 {
                    color: $dark-dark_grey;
                }
            }
        }

        .order-list-img {
            .like-wrap {
                background: rgba(255, 255, 255, 0.3);
            }
        }
    }

    .choice-chicken-wrap {
        h4 {
            color: $dark-full_Black;
        }

        .choice-list-wrap {
            .list {
                .txt-block {
                    h5 {
                        color: $dark-full_Black;
                    }

                    span {
                        color: $light-dark_grey;
                    }
                }
            }
        }
    }

    .offer-details-wrap {
        .apy-btn {
            background: $light-full_Black;
            color: $light-negative;
        }
    }

    .offer-list-wrap {
        .offer-list {
            .list-block {
                .offer-list-txt {
                    h6 {
                        color: $dark-full_Black;
                    }

                    p {
                        color: $dark-dark_grey;
                    }

                    button {
                        color: $light-chilly_red;
                    }
                }

                .offer-block {
                    background: $light-chilly_red;

                    h4 {
                        color: $light-negative;
                    }

                    span {
                        color: $light-negative;
                    }
                }
            }

            p {
                color: $light-dark_grey;
            }

            &.inactive {
                .list-block {
                    .offer-list-txt {
                        a {
                            color: $light-light_grey;
                        }
                    }
                }

                .offer-block {
                    background: $dark-light_grey;
                }
            }
        }
    }

    .ordertracking {
        .sub-title {
            color: $dark-full_Black;
        }

        .remarks-block {
            p {
                color: $dark-full_Black;
            }
        }

        .total-txt {
            border-top: 0.5px dashed $dark-dark_grey;
        }
    }

    .cartitems-wrap {
        border: transparent;
        background: $dark-light_grey;

        .cartitems-title {
            color: $dark-full_Black;
        }

        .cartitems-list {
            .cartitem {
                .link-wrap {
                    .cart-link {
                        color: $light-chilly_red;
                    }

                    svg {
                        path {
                            stroke: $dark-full_Black;
                        }
                    }
                }

                .cart-details {
                    .title-box {
                        h4 {
                            color: $dark-full_Black;
                        }

                        button {
                            border: 1px solid $dark-light_grey;

                            svg {
                                path {
                                    stroke: $dark-full_Black;
                                }
                            }
                        }
                    }

                    .quantity-details {
                        span {
                            color: $dark-full_Black;
                        }
                    }
                }
            }
        }
    }

    .addmoreitems-wrap {
        .addmoreitems {
            background: $dark-negative;

            .addmoreitems-details {
                h6 {
                    color: $dark-full_Black;
                }

                p {
                    color: $dark-dark_grey;
                }

                span {
                    color: $light-dark_grey;
                }

                button {
                    background: $dark-chilly_red;
                    color: $dark-negative;
                }
            }
        }
    }

    .tab-container {
        .tabs {
            .tab {
                border: none;
                background: $dark-light_grey;

                &:hover {
                    background: $dark-amber;

                    h4 {
                        color: $light-negative;
                    }
                }

                &.isactive {
                    background: $dark-amber;

                    h4 {
                        color: $light-negative;
                    }
                }

                h4 {
                    color: $dark-dark_grey;
                }
            }
        }
    }

    .cart-billing-details {
        ul {
            li {
                span {
                    color: $dark-dark_grey !important;

                    small {
                        color: $dark-dark_grey;
                    }
                }

                &.total-txt {
                    color: $dark-full_Black !important;

                    small {
                        color: $dark-full_Black !important;
                    }

                    b {
                        color: $dark-full_Black !important;
                    }

                    span {
                        color: $dark-full_Black !important;
                    }
                }
            }
        }

        .checkout-btn {
            background: $light-chilly_red;
            color: $light-negative;
        }
    }

    .coupon-txt {
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;

        h5 {
            color: $dark-full_Black;
        }

        svg {
            path {
                stroke: $dark-full_Black;
            }
        }
    }

    .order-id {
        ul {
            li {
                .default_btn {
                    background-color: #faf8f4;
                    color: $light-full_Black;
                    border: solid 1px #e6e6e6;
                }

                span {
                    color: $dark-full_Black;

                    &.statusTime {
                        color: $light-amber;
                    }

                    &.statusSuccess {
                        color: #58b410;
                    }
                }

                &:last-child {
                    span {
                        color: $light-amber;
                    }
                }
            }
        }
    }

    .track-order-wrap {
        .order-tracking-wrap {
            ul {
                li {
                    span {
                        color: $dark-full_Black;
                    }

                    .progtrckr-wrap {
                        .progtrckr-done {
                            background: $light-green;

                            &::after {
                                background: $light-green;
                            }
                        }
                    }

                    .date-block {
                        span {
                            color: $light-amber;
                        }

                        .date-txt {
                            color: $dark-full_Black;
                        }
                    }
                }
            }
        }
    }

    .delivery-block {
        span {
            color: $dark-full_Black;
        }

        svg {
            path {
                stroke: $dark-full_Black;
            }
        }
    }

    .order-address-wrap {
        .order-address {
            ul {
                li {
                    border-bottom: 1px solid $dark-dark_grey;

                    div {
                        span {
                            color: $dark-full_Black;
                        }
                    }

                    svg {
                        path {
                            stroke: $dark-full_Black;
                        }
                    }
                }
            }
        }
    }

    .order-item-wrap {
        .order-item {
            .order-count {
                span {
                    color: $dark-full_Black;
                }
            }
        }
    }

    .cartitems-remarks {
        h4 {
            color: $dark-full_Black;
        }

        textarea {
            border: 1px solid $light-light_grey;
            background: $dark-light_grey;
            color: $dark-full_Black;
            text-align: right !important;
        }
    }

    .payment-wrap-modal {
        .payment-wrap {
            .payment-list {
                .icon-block {
                    background: $dark-light_grey;

                    svg {
                        path {
                            stroke: $dark-full_Black;
                        }
                    }
                }

                span {
                    color: $dark-full_Black;
                }
            }
        }
    }

    .category-detail-modal {
        .chicking-modal-wraper {
            .chicking-modal-body {
                background: $dark-light_grey;
            }

            .tab {
                &.isactive {
                    background: $light-amber;
                }
            }

            .detail-modal-wrap {
                .left-block {
                    background: $dark-negative;

                    .img-block {
                        &::after {
                            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.05%, rgba(0, 0, 0, 0.7) 74%);
                        }

                        .offer-block {
                            .offer-txt {
                                color: $light-negative;
                                background: $light-chilly_red;
                            }

                            .share-block {
                                button {
                                    background: rgba(255, 255, 255, 0.1);

                                    &:hover {
                                        border: 1px solid $light-board_grey;
                                    }
                                }
                            }
                        }
                    }

                    .txt-block {
                        h3 {
                            color: $dark-full_Black;
                        }

                        .graphic-block-wrap {
                            span {
                                color: $dark-dark_grey;
                            }
                        }
                    }

                    .category-quantity {
                        span {
                            color: $dark-full_Black;
                        }
                    }

                    .description-block {
                        h4 {
                            color: $dark-full_Black;
                        }

                        p {
                            color: $dark-full_Black;

                            a {
                                color: $dark-chilly_red;
                            }
                        }
                    }
                }

                .right-block {
                    .choice-title {
                        h4 {
                            color: $dark-full_Black;
                        }

                        .selected-block {
                            border: 1px solid $dark-amber_card_background;
                            background: $dark-negative;

                            .icon-block {
                                li {
                                    &.icon-bg {
                                        border: 1px solid $dark-shaded_white;
                                        background: $light-green;
                                    }

                                    span {
                                        color: $light-amber;
                                    }
                                }
                            }

                            h6 {
                                color: $dark-full_Black;
                            }
                        }
                    }

                    .cartitems-list {
                        .cartitem {
                            .cart-details {
                                .title-box {
                                    h4 {
                                        color: $dark-full_Black;
                                    }

                                    span {
                                        color: $light-dark_grey;
                                    }
                                }

                                .quantity-details {
                                    .quantitywrap {
                                        border: 1px solid $light-light_grey !important;

                                        input {
                                            background: transparent !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .chicking-modal-footer {
            border-top: 1px solid $dark-light_grey;
            background: $dark-negative;

            .checkbox {
                p {
                    color: $dark-full_Black;

                    b {
                        color: $dark-chilly_red;
                    }
                }
            }

            .primary-btn {
                &.isdisabled {
                    background: $dark-light_grey !important;
                    color: $dark-negative !important;

                    svg {
                        path {
                            stroke: $dark-negative !important;
                        }
                    }
                }
            }
        }
    }

    .toggle-button-cover {
        span {
            color: $dark-full_Black;
        }

        .slider {
            border: 1px solid $light-light_grey;

            &:before {
                border: 1px solid $light-shade_prime;
                background: $light-light_grey;
            }
        }

        input:checked+.slider:before {
            border: 1px solid $light-shade_prime;
            background: $light-light_grey;
            background: $light-chilly_red;
        }
    }

    .outlets-items {
        figure {
            &::after {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
            }

            figcaption {
                h6 {
                    color: $light-negative;

                    span {
                        color: $light-negative;
                    }
                }

                .btn-group {
                    .btn {
                        background: $light-chilly_red;
                        color: $light-negative;
                    }

                    .btn-icon {
                        svg {
                            path {
                                stroke-width: 1px;
                                stroke: $light-negative;
                            }
                        }
                    }
                }
            }
        }
    }

    .slick-arrow {
        border: 1px solid $light-light_grey !important;
        background: $light-shaded_white url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-left-white.svg) no-repeat !important;
        background-position: center !important;

        &.isActive {
            background: $light-chilly_red url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg) !important;
        }

        &.slick-next {
            background: $light-shaded_white url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg) no-repeat !important;
            background-position: center !important;

            &.isActive {
                background-image: $light-chilly_red url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg) !important;
            }
        }
    }

    .address-book {
        .item-locations {
            .item-header {
                .dropdown-block {
                    span {
                        background: $dark-negative;
                        color: $dark-full_Black;
                    }
                }
            }
        }

        .location-wrap svg path {
            fill: $dark-full_Black !important;
            stroke: none !important;
        }

        .dropdown-btn {
            .dropdown {
                ul {
                    border: 0.5px solid $dark-light_grey;
                    background: $dark-negative;
                    border-radius: 5px;
                    position: relative;
                    top: 30px;
                    width: 100px;

                    li {
                        border-top: 1px solid $dark-light_grey !important;

                        button {
                            color: $dark-full_Black !important;
                        }
                    }
                }
            }
        }
    }
}