// variable

$light-negative:#ffffff;
$light-full_Black: #000000;
$light-light_grey:#e6e6e6;
$light-dark_grey:#7f7f7f;
$light-board_grey:#afafaf;
$light-chilly_red:#be1622;
$light-chilly_red_dark:#891b1c;
$light-shaded_white:rgba(255,255,255,13%);
$light-full_White:#ffffff;
$light-prime_background : #faf8f4;
$light-shade_prime: rgba(250,248,244,7%);
$light-card_background:#ffffff;
$light-amber:#f39200;
$light-amber_card_background:#f8f2e9;
$light-green:#58B410;
$light-golden-gradient: linear-gradient(180deg, #fcf7f0 0%, #fceed7 100%);

$dark-negative:#202021;
$dark-full_Black: #fff;
$dark-light_grey:#333;
$dark-dark_grey:#888;
$dark-chilly_red:#be1622;
$dark-chilly_red_dark:#891b1c;
$dark-shaded_white:rgba(24,24,25,13%);
$dark-full_White:#202021;
$dark-prime_background : #181819;
$dark-shade_prime: rgba(24,24,25,30%);
$dark-card_background:#262629;
$dark-amber:#f39200;
$dark-amber_card_background:#f8f2e9;


$border-radius-small:4px;
$gap-regular:25px;
$gap-medium:25px;
