* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    width: 100%;
    height: auto;
    scroll-behavior: smooth;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Gilroy-Light', sans-serif;
    margin: 0 auto;
    word-break: break-word;
}

#root {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

// scrollbar
::-webkit-scrollbar {
    width: 10px;
    border-radius: 6px;
    height: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    background: #30304d;
    border-radius: 6px;
}

// reusable styles
@mixin full-space {
    width: 100%;
    height: 100%;
}

@mixin flex {
    display: flex;
}

@mixin flex-align($align: center) {
    align-items: $align;
}

@mixin flex-direction($direction: row) {
    flex-direction: $direction;
}

@mixin flex-justify($justify: center) {
    justify-content: $justify;
}

@mixin para-line($line-number) {
    display: -webkit-box;
    -webkit-line-clamp: $line-number;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}

// common styles
a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

button {
    background: inherit;
    outline: none !important;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in-out;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
    margin: 0;
}

.pt-100 {
    padding-top: 100px;

    @media (max-width: 1367px) {
        padding-top: 70px !important;
    }

    @media (max-width: 1199px) {
        padding-top: 50px !important;
    }
}

.pb-100 {
    padding-bottom: 100px;

    @media (max-width: 1367px) {
        padding-bottom: 70px !important;
    }

    @media (max-width: 1199px) {
        padding-bottom: 50px !important;
    }
}

.pt-50 {
    padding-top: 50px;

    @media (max-width: 767px) {
        padding-top: 15px !important;
    }
}

.pb-50 {
    padding-bottom: 50px;

    @media (max-width: 767px) {
        padding-bottom: 30px !important;
    }
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.cus-row {
    display: flex;
    margin: 0 -12px;
    flex-wrap: wrap;

    .col-1,
    .col-2,
    .col-3,
    .col-4 {
        padding: 0 12px;
    }

    .col-1 {
        width: 100%;
    }

    .col-2 {
        width: calc(100% / 2);
    }

    .col-3 {
        width: calc(100% / 3);
    }

    .col-4 {
        width: calc(100% / 4);
    }
}

.radio-box {
    position: relative;

    input {
        padding: 0;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        z-index: 9;
    }

    span {
        position: relative;
        cursor: pointer;
        width: 18px;
        height: 18px;
        display: block;

        &:before {
            content: '';
            border-radius: 6px;
            border: 1px solid $light-board_grey;
            background: $light-negative;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            width: 18px;
            height: 18px;
            transition: all 0.5s ease-in-out;
        }
    }

    input:checked+span::before {
        background: $light-chilly_red;
        transition: all 0.5s ease-in-out;
    }

    input:checked+span:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 1px;
        background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/TickIcon.svg) no-repeat;
        margin: auto;
        background-position: center;
        background-size: 10px;
        width: 18px;
        height: 18px;
    }
}

.error-message {
    font-size: 12px;
    color: $light-chilly_red;
    font-family: 'Gilroy-Bold', sans-serif;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-disabled {
    cursor: not-allowed !important;
}

.justify-end {
    justify-content: flex-end;
}

.d-flex {
    display: flex;
}

/////////Date picker///////
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

/* Header styles */
.react-datepicker__header {
    background: $light-negative;
    border: none !important;
    padding: 10px 0;
}

.react-datepicker__current-month {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

/* Style for the year dropdown in header */
.react-datepicker__year-dropdown-container {
    color: #fff;
}

.react-datepicker__year-dropdown {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-height: 150px;
    overflow-y: auto;
    padding: 5px 0;
    width: 100px;
    text-align: center;
}

.react-datepicker__year-option {
    padding: 8px;
    font-size: 14px;
    color: #be1622;
    /* Red color for year options */
    cursor: pointer;
}

.react-datepicker__year-option:hover {
    background-color: #be1622;
    /* Red background on hover */
    color: #ffffff;
}

.react-datepicker__year-option--selected {
    background-color: #be1622;
    /* Red background for selected year */
    color: #ffffff;
    font-weight: bold;
}

/* Day styles */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #be1622;
    /* Red background for selected day */
    color: #fff !important;
}

.react-datepicker__day--today {
    color: #be1622;
}



/* Dropdown year items */
.react-datepicker__year-select {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid $light-light_grey;
    background: $light-negative;
    height: 44px;
    padding: 14px;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 14px;
    width: 80px;
    margin-bottom: 10px;
}

h2.react-datepicker__current-month {
    padding-bottom: 10px;
    color: $light-full_Black;
    font-family: "Gilroy-bold", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.14px;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
    border: none;
    transform: unset;
    height: 11px;
    top: 13px;
    background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/arrow.svg) no-repeat;
}

.react-datepicker__navigation-icon--previous::before {
    transform: rotate(180deg);
    transform-origin: center;
}

.react-datepicker__day-name {
    color: $light-full_Black;
    font-family: "Gilroy-Medium", sans-serif;
}

.react-datepicker__day {
    color: $light-dark_grey;
    font-family: "Gilroy-Medium", sans-serif;
}

.boder-none {
    border: none !important;
}

.asteriskValid {
    color: $light-chilly_red;
}

.width-100 {
    width: 100%;
}

.Toastify__toast {
    padding: 12px 16px !important;
    align-items: center;
    gap: 15px;
    border-radius: 8px !important;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20) !important;
    border: 1px solid transparent;
    min-height: 50px !important;

    .Toastify__toast-body {
        padding: 0 !important;

        &>div:last-child {
            color: $light-full_Black;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: 0.5px;

        }
    }

    .Toastify__close-button {
        align-self: center;
        opacity: 1;
    }

    button {
        padding: 0;

        svg {
            width: 14px;
            height: 14px;
        }
    }

    &.Toastify__toast--error {
        background: $light-chilly_red;
    }

    &.Toastify__toast--warning {
        background: $light-amber;
    }

    &.Toastify__toast--success {
        background: $light-green;
    }

    &.Toastify__toast--info {
        background: #44a3e3;
    }

    &.Toastify__toast--error,
    &.Toastify__toast--warning,
    &.Toastify__toast--success,
    &.Toastify__toast--info {
        .Toastify__toast-body {

            &>div:last-child {
                color: $light-full_White !important;
            }
        }

        svg {
            path {
                fill: $light-full_White;
            }
        }
    }

    &.Toastify__toast--default {
        border: 1px solid #E1E1E1;
        background: #F1F1F1;
    }
}
.Toastify__toast-container {
    z-index: 111111 !important;
}
.Toastify__toast-theme--dark {
    &.Toastify__toast--default {
        border: 1px solid #888;
        background: #242C32;

        .Toastify__toast-body {

            &>div:last-child {
                color: $light-full_White !important;
            }
        }

        svg {
            path {
                fill: $light-full_White;
            }
        }
    }
}

.overflow-hidden{
    overflow: hidden !important;
}