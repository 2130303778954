$NEXT_PUBLIC_CDN_URL: 'https://dev-chicking-cdn.emvigotech.co.uk';
// CountrySelect.scss
.country-select {
  position: relative;
  width: 100%;
  max-width: 400px;
  font-family: Arial, sans-serif;

  @media (max-width:767px) {
    max-width: 100%;
  }

  .phone-input {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
  }

  .selected-country {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: #fff !important;
    border-radius: 0 !important;
    cursor: pointer;
    justify-content: flex-start !important;
    min-width: fit-content;
    width: unset !important;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: #ebebeb;
    }

    .flag {
      margin-right: 4px;
      border-radius: 2px;
      max-width: 30px;
      flex: none;
    }

    .phone-code {
      color: var(--Black, #000);
      font-family: "Gilroy-Medium", sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin-right: 4px;
    }

    .arrow {
      font-size: 12px;
      color: #666;
      transform-origin: center;
      transform: rotate(90deg);
      margin: 0 5px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  .phone-number-input {
    flex: 1;
    border: none;
    outline: none;
    color: var(--Black, #000);
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 14px;
    font-weight: 500;

    &::placeholder {
      color: #999;
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .search-input-wrapper {
      padding: 4px 7px;
    }

    .search-input {
      width: 100%;
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid var(--Grey, #E6E6E6);
      background: var(--Negative, #FFF);
      font-size: 14px;
      display: block;
      margin: auto;
      margin-top: 10px;
      height: 44px;

      &::placeholder {
        color: #999;
      }
    }

    .countries-list {
      max-height: 200px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
    }

    .country-option {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      cursor: pointer;
      background: transparent !important;
      color: var(--Black, #000) !important;
      font-family: "Gilroy-Medium", sans-serif;
      font-size: 12px;
      justify-content: flex-start !important;

      &:hover {
        background: #f5f5f5;
      }

      .flag {
        margin-right: 8px;
        border-radius: 2px;
      }

      .country-name {
        flex: 1;
        margin-right: 8px;
      }

      .country-code {
        color: #666;
      }
    }
  }

  &[dir="rtl"] {
    .selected-country {
      border-right: none;

      .flag {
        margin-right: 0;
        margin-left: 8px;
      }

      .phone-code {
        margin-right: 0;
        margin-left: 8px;
      }
    }

    .country-option {
      .flag {
        margin-right: 0;
        margin-left: 8px;
      }

      .country-name {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}

.location-address-form {
    .phone-input {
        border-radius: 8px;
        border: 1px solid var(--Grey, #e6e6e6);
        background: var(--Negative, #fff);
        height: 44px;
        padding: 14px;
        font-family: "Gilroy-Medium", sans-serif;
        font-size: 14px;
        width: 100%;
        align-items: center;
    }

    .selected-country {
        padding:0;
    }
}

.flag-placeholder {
  width: 24px;
  height: 16px;
  background: #f0f0f0;
  border-radius: 2px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
